import Layout from './Layout'
import avatar from '../../../assets/img/avatar.gif'
import { NavLink } from 'react-router-dom'
import WithdrawalIcon from '@/components/shared/icons/web/WithdrawalIcon'
import ArrowNarrowLeftIcon from '@/components/shared/icons/ArrowNarrowLeftIcon'
import ArrowRight from '@/components/shared/icons/web/ArrowRight'
import CardIcon from '@/components/shared/icons/web/CardIcon'
import MenuIcon1 from '@/components/shared/icons/web/MenuIcon1'
import MenuIcon2 from '@/components/shared/icons/web/MenuIcon2'
import MenuIcon3 from '@/components/shared/icons/web/MenuIcon3'
import ChangePassWordIcon from '@/components/shared/icons/web/ChangePassWordIcon'
import LogoutIcon from '@/components/shared/icons/header/LogoutIcon'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { logout, resetAll } from '../auth/auth.reducer'
import MenuIcon2_1 from '@/components/shared/icons/web/MenuIcon2_1'
import SModal from '@/components/shared/Modal/SModal'
import { CButton, CModalBody, CModalFooter, CModalHeader } from '@coreui/react-pro'
import { useState } from 'react'
import MenuIcon1_1 from '@/components/shared/icons/web/MenuIcon1_1'

const Account = () => {
  const { user } = useSelector((state: RootState) => state.authentication)
  const dispatch = useDispatch<AppDispatch>()

  const [visible, setVisible] = useState(false)

  const onLogout = () => {
    dispatch(logout())
    dispatch(resetAll())
  }

  return (
    <Layout>
      <div className="box-account">
        <div className="box-header">
          <div className="text-center text-white mt-3 mb-2" style={{ fontWeight: 500 }}>
            Trung tâm thành viên
          </div>

          <div className="d-flex gap-2 align-items-center my-2 justify-content-between">
            <div className="text-white" style={{ fontSize: 15 }}>
              ID: {user?.id}
            </div>
            <div className="text-white">{user?.username}</div>
          </div>

          <div className="pb-5 d-flex justify-content-center text-center flex-column">
            <div style={{ fontSize: 14 }} className="text-white mt-2">
              Số dư tài khoản
            </div>
            <div className="text-white fs-4 mt-3">
              {new Intl.NumberFormat('vi-VN').format(user?.money || 0)}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div
            className="d-flex rounded-5"
            style={{
              padding: 10,
              borderRadius: 10,
              borderColor: '#e2e5ec',
              borderWidth: 1,
              borderStyle: 'solid',
              background: '#ea03b8',
              marginTop: '-36px',
            }}
          >
            <NavLink
              to="/withdraw"
              className=" text-decoration-none text-white d-flex justify-content-center align-items-center flex-column"
              style={{
                fontSize: 14,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 35,
                paddingLeft: 35,
              }}
            >
              <svg
                style={{ fill: '#fff', width: 22, height: 22 }}
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="PriceChangeOutlinedIcon"
              >
                <path d="M8 17h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1H8v-1h4V8h-2V7H8v1H7c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H6v2h2v1zM20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4V6h16v12zm-6-8 2-2 2 2m0 4.25-2 2-2-2"></path>
              </svg>
              <span className="mt-1">Rút tiền</span>
            </NavLink>
          </div>
        </div>

        {/* <div className="naprut">
          <NavLink
            to="/withdraw"
            className="gap-2 d-flex align-items-center py-2"
          >
            <WithdrawalIcon height={30} width={30} color={'#1989fa'} />
            Rút điểm
          </NavLink>
        </div> */}

        <div className="list-menu mt-4 px-3">
          <NavLink to={'/history-order'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: 'rgb(55, 65, 81)', fontSize: 15 }}
            >
              <MenuIcon1 color="#rgb(55, 65, 81)" width={24} height={24} />
              Lịch sử tham gia
            </div>
            <ArrowRight color={'rgb(55, 65, 81)'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/rewards'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: 'rgb(55, 65, 81)', fontSize: 15 }}
            >
              <MenuIcon1_1 color="rgb(55, 65, 81)" width={24} height={24} />
              Lịch sử nhận thưởng
            </div>
            <ArrowRight color={'rgb(55, 65, 81)'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/history-withdraw'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: 'rgb(55, 65, 81)', fontSize: 15 }}
            >
              <MenuIcon2_1 color="rgb(55, 65, 81)" width={24} height={24} />
              Lịch sử rút
            </div>
            <ArrowRight color={'rgb(55, 65, 81)'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/add-bank'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: 'rgb(55, 65, 81)', fontSize: 15 }}
            >
              <MenuIcon3 color="rgb(55, 65, 81)" width={24} height={24} />
              Điền ngân hàng
            </div>
            <ArrowRight color={'rgb(55, 65, 81)'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/change-password'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: 'rgb(55, 65, 81)', fontSize: 15 }}
            >
              <ChangePassWordIcon color="rgb(55, 65, 81)" width={24} height={24} />
              Đổi mật khẩu
            </div>
            <ArrowRight color={'rgb(55, 65, 81)'} width={12} height={12} />
          </NavLink>

          <NavLink to={'#'} className="mt-4" onClick={onLogout}>
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: 'rgb(55, 65, 81)', fontSize: 15 }}
            >
              <LogoutIcon width={24} height={24} />
              Đăng xuất
            </div>
            <ArrowRight color={'rgb(55, 65, 81)'} width={12} height={12} />
          </NavLink>
        </div>
      </div>

      <div>
        <SModal
          visible={visible}
          backdrop="static"
          className="custom-modal"
          alignment="center"
        >
          <CModalBody>
            <h2 className="text-center mb-4">NẠP ĐIỂM</h2>
            <p className='m-0'>Vui lòng liên hệ CSKH để được hướng dẫn nạp điểm</p>
          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton style={{ background: "#ddd", color: "rgb(55, 65, 81)", boxShadow: "none"}} onClick={() => setVisible(false)}>Đóng</CButton>
          </CModalFooter>
        </SModal>
      </div>
    </Layout>
  )
}

export default Account
