import { RootState } from '@/reducers'
import MenuBottom from './MenuBottom'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useEffect, useState } from 'react'
import { socket } from '@/shared/config/socket'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import SModal from '@/components/shared/Modal/SModal'
import { CButton, CModalBody, CModalFooter } from '@coreui/react-pro'

import qua1 from '../../../assets/img/qua1.png'
import axios from '../../../shared/config/axios-interceptor'
import { setDeposit } from '../auth/auth.reducer'
import FallingShapes from './FallingShapes'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { navigate, location } = useRouter()
  const dispatch = useDispatch<AppDispatch>()
  const { user, deposit } = useSelector((state: RootState) => state.authentication)
  const [disableModal, setDisableModal] = useState(false)

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to server')
    })

    socket.on(`user_${user?.id}_new_message`, (newMsg: any) => {
      console.log('Bạn có tin nhắn mới: ', newMsg)
    })
    console.log('data', user?.id)

    socket.on(`user_${user?.id}_new_deposit`, (data: any) => {
      console.log('data', data)

      if (data) {
        dispatch(getProfile())
      }

      if (data && data.isNotify) {
        dispatch(setDeposit({ isVissible1: true, isVissible2: true, data: data }))
      }
    })

    return () => {
      socket.off(`user_${user?.id}_new_message`)
      socket.off(`user_${user?.id}_new_deposit`)
      // socket.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const intrval = setInterval(() => {
      dispatch(getProfile())
    }, 1200)
    return () => clearInterval(intrval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [noti, setNoti] = useState<any>(null)
  const getNotiLatest = async () => {
    try {
      const response = await axios.get('/transaction/get-noti-latest')
      console.log('Success:', response.data)
      if (response?.data && response?.data?.isSeen === 0) {
        dispatch(setDeposit({ isVissible1: true, data: response?.data }))
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getNotiLatestNoti = async () => {
    try {
      const response = await axios.get('/notify/get-noti-latest')
      console.log('Success:', response.data)
      if (response?.data) {
        setNoti(response?.data)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handlerSeen = async () => {
    try {
      const response = await axios.get('/transaction/seen')
      console.log('Success:', response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getNotiLatest()
  }, [])


  // useEffect(() => {
  //   const a = setInterval(() => {
  //     getNotiLatestNoti()
  //   }, 1000)
  //   return () => clearInterval(a)
  // }, [])

  const handleOnClose = () => {
    dispatch(setDeposit({ ...deposit, isVissible1: false }))
    handlerSeen()
  }

  const handlerSeenNoti = async () => {
    try {
      return await axios.get('/notify/seen?id=' + noti?.id)
    } catch (error) {
      console.error('Error:', error)
    }
  }


  const setClose = async () => {
    setDisableModal(true)
    const data = await handlerSeenNoti();
    if(data){
      setNoti(null)
      setDisableModal(false)
    }
  }

  return (
    <>
      <div className="container-web">
        {children}
        <div className="bottom-bar">
          <div className="info-current-user d-flex justify-content-between align-items-center">
            <div className="" style={{ fontSize: '13px' }}>
              Số dư tài khoản: {new Intl.NumberFormat('vi-VN').format(user?.money || 0)}
            </div>
            <div className="" style={{ fontSize: '13px' }}>Mã thành viên: {user?.id}</div>
          </div>
          <MenuBottom />
        </div>
      </div>
      <div>
        <SModal
          visible={deposit?.isVissible1}
          onClose={handleOnClose}
          backdrop="static"
          className="custom-modal custom-1"
          alignment="center"
        >
          <FallingShapes />

          {/* <CModalHeader>
            <h5>Thông báo Nạp Tiền</h5>
          </CModalHeader> */}
          <CModalBody>
            <div className="text-center">
              <img width={120} src={qua1} />
            </div>

            <p style={{ color: 'gray' }} className="mt-3">
              {deposit?.data?.message}
            </p>

          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton className='px-4 rounded-5' onClick={handleOnClose} style={{ background: '#ea03b8', zIndex: 2, opacity: 0.9 }}>
              Đồng ý
            </CButton>
          </CModalFooter>
        </SModal>

        <SModal
          visible={noti ? true : false}
          backdrop="static"
          className="custom-modal custom-1"
          alignment="center"
        >
          <FallingShapes />
          <CModalBody style={{ minHeight: '200px' }}>
            {/* <div className="text-center">
              <img width={120} src={qua1} />
            </div> */}

            <p style={{ color: 'gray' }} className="mt-3">
              {noti?.message}
            </p>

          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton disabled={disableModal} className='px-4 rounded-5' onClick={() => setClose()} style={{ background: '#ea03b8', zIndex: 2, opacity: 0.9 }}>
              Đồng ý
            </CButton>
          </CModalFooter>
        </SModal>

      </div>
    </>
  )
  
}

export default Layout
