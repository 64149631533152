import AccountIcon from "@/components/shared/icons/web/AccountIcon";
import CskhIcon from "@/components/shared/icons/web/CskhIcon";
import HeThongIcon from "@/components/shared/icons/web/HeThongIcon";
import HomeIcon from "@/components/shared/icons/web/HomeIcon";
import { NavLink } from "react-router-dom";

const MenuBottom = () => {
    return <div className="menu-bottom d-flex justify-content-between align-items-center">
        <NavLink to={'/'} className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center">
            <svg style={{width: '20px', height: '20px'}} data-v-bd832875="" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" 
            className="icon text-[20px]" width="20" height="20" viewBox="0 0 24 24">
                <path fill="#fff"
                 d="M6 21q-.825 0-1.412-.587T4 19v-9q0-.475.213-.9t.587-.7l6-4.5q.525-.4 1.2-.4t1.2.4l6 4.5q.375.275.588.7T20 10v9q0 .825-.588 1.413T18 21zm2-8.45q0 1.65.95 3.2t2.575 2.1q.125.05.237.063t.238.012t.238-.012t.237-.063q1.625-.55 2.575-2.1t.95-3.2v-1.625q0-.425-.225-.788t-.6-.562l-2.5-1.25q-.325-.15-.675-.15t-.675.15l-2.5 1.25q-.375.2-.6.563T8 10.925z"></path></svg>
            <span>Vòng quay</span>
        </NavLink>
        <NavLink to={'/history'} className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center">
            <HeThongIcon style={{ fill: '#fff' }} />
            <span>Giới thiệu</span>
        </NavLink>
        <NavLink to={'/account'} className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center">
            <AccountIcon style={{ fill: '#fff' }} />
            <span>Cá Nhân</span>
        </NavLink>
        <NavLink to={'/message'} className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center">
            <CskhIcon style={{ fill: '#fff' }} />
            <span>CSKH</span>
        </NavLink>
    </div>;
}

export default MenuBottom;