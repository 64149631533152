import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react-pro';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ResponseStatus} from '../../../shared/enumeration/ResponseStatus';
import { IParams, ISelectValue } from '../../../shared/shared-interfaces';
import SModal from '../../shared/Modal/SModal';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { AppDispatch } from '../../../store';
import { ToastSuccess } from '../../shared/toast/Toast';
import { createEntity, updateEntity } from './ketQuaQuayManagement.api';
import { fetching, resetEntity } from './ketQuaQuayManagement.reducer';
import { permissionSelectors } from '../SystemSetting/PermissionGroup/permission.reducer';
import { IKetQuaQuay, INewKetQuaQuay } from '@/shared/model/ketquaquay.model';

interface IUserUpdateProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  userObj?: IKetQuaQuay;
}

const KetQuaQuayUpdate = (props: IUserUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector((state: RootState) => state.ketQuaQuayReducer);
  const { updateEntitySuccess } = initialState;
  const { visible, setVisible, userObj } = props;
  const handleOnClose = () => {
    setVisible(false);
  };

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);
  const permissionItems = useSelector(permissionSelectors.selectAll);

  const [permissionFilter, setPermissionFilter] = useState<IParams>({
    page: 1,
    limit: 20,
  });


  useEffect(() => {
    if (updateEntitySuccess) {
      setVisible(false);
      ToastSuccess(userObj ? 'Cập nhật tài khoản thành công' : 'Tạo tài khoản thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  const initialValues: INewKetQuaQuay = {
    name: '',
    value: 0,
    note: '',
    phanQuaId: 0,
    userId: 0,
    status: ResponseStatus.ACTIVE,
  };

  const validationSchema = Yup.object().shape({
    note: Yup.string()
      .trim()
      .required('Không được để trống'),
    value: Yup.number().required('Không được để trống').nullable(),
    status: Yup.string().trim().required('Không được để trống'),
  });

  const returnOption = (optionValue: number | null | undefined, array: ISelectValue<string>[]) => {
    if (!optionValue) return null;
    if (!array.length) return null;
    return array.find(({ value }) => Number(value) === optionValue);
  };

  return (
    <SModal visible={visible} onClose={handleOnClose} backdrop="static" className="custom-modal">
      <Formik
        initialValues={userObj || initialValues}
        validationSchema={validationSchema}
        // validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(value) => {
          dispatch(fetching());
          if (userObj) {
            dispatch(updateEntity(value as IKetQuaQuay));
          } else {
            dispatch(createEntity(value));
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue }) => (
          <CForm onSubmit={handleSubmit} className="custom-form">
            <>
              <CModalHeader>{userObj ? 'Cập nhật kết quả quay' : 'Tạo kết quả quay'}</CModalHeader>
              <CModalBody>
                <CRow className="gap-20">
                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Ghi chú</CFormLabel>

                    <CFormInput
                      className="form-height-44"
                      name="note"
                      autoComplete="off"
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.note && touched.note}
                    />
                    <CFormFeedback invalid className={!!errors.note && touched.note ? 'd-block' : 'd-none'}>
                      {errors.note}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Số dư: <strong className='text-danger'>{new Intl.NumberFormat('vi-VN').format(values?.value || 0)}</strong></CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="value"
                      value={values.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.value && touched.value}
                    />
                    <CFormFeedback invalid className={!!errors.value && touched.value ? 'd-block' : 'd-none'}>
                      {errors.value}
                    </CFormFeedback>
                  </CCol>

                  {/* <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Trạng thái</CFormLabel>
                    <CFormSelect
                      className="form-height-44"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.status && touched.status}
                    >
                      {responseStatusArray.map((item, index) => (
                        <option key={index} value={item}>
                          {mapResponseStatus[item]}
                        </option>
                      ))}
                    </CFormSelect>
                    <CFormFeedback invalid className={!!errors.status && touched.status ? 'd-block' : 'd-none'}>
                      {errors.status}
                    </CFormFeedback>
                  </CCol> */}
                </CRow>
              </CModalBody>
              <CModalFooter className="d-flex justify-content-end">
                <CButton
                  className="btn-custom minw-120 variant-gray-300"
                  type="button"
                  onClick={() => {
                    resetForm();
                    setVisible(false);
                  }}
                >
                  Huỷ
                </CButton>
                <CButton className="btn-custom minw-120 primary-500" type="submit">
                  Lưu
                </CButton>
              </CModalFooter>
            </>
          </CForm>
        )}
      </Formik>
    </SModal>
  );
};

export default KetQuaQuayUpdate;
