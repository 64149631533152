import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { CButton, CFormInput, CFormLabel, CHeader, CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import axios from '../../../shared/config/axios-interceptor';
import { ToastSuccess } from "@/components/shared/toast/Toast";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { AppDispatch } from "@/store";
import { getProfile } from "../auth/auth.api";
import axiosInstance from "axios";
import SModal from "@/components/shared/Modal/SModal";

const AddBank = () => {
    const { navigate } = useRouter();
    const dispatch = useDispatch<AppDispatch>()

    const { user } = useSelector((state: RootState) => state.authentication);

    const [cardName, setCardName] = useState(user?.cardName || '');
    const [cardNumber, setCardNumber] = useState(user?.cardNumber || '');
    const [bankName, setBankName] = useState(user?.bankName || '');

    const [visible, setVisible] = useState(false);

    const [bank, setBank] = useState([]);

    const getBank = async () => {
        try {
            const response = await axiosInstance.get('https://servicemb.k8sprod.site/bank.json')
            console.log(response.data)
            setBank(response.data)
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getBank()
    }, [])

    const onChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (index === 1) {
            setCardName(value)
        }
        if (index === 2) {
            setCardNumber(value)
        }
        if (index === 3) {
            setBankName(value)
        }
    }

    const onSubmit = async () => {
        try {
            const response = await axios.put('/user/' + user?.id, {
                cardName,
                cardNumber,
                bankName
            });
            ToastSuccess('Cập nhật ngân hàng thành công')
            dispatch(getProfile())
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const Selected = (name: string) => {
        setBankName(name)
        setVisible(false)
    }

    return (
        <Layout >
            <div className="box-add-bank">

                <div className="box-header">
                    <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />
                    <h5 className="m-0 text-white" style={{ fontSize: 16, fontWeight: 400 }}>Điền Ngân Hàng Nhận</h5>
                </div>

                <div className="box-form">

                    <div className="form-group-custom">
                        <CFormLabel htmlFor="bankName" className="col-sm-2 col-form-label text-nowrap">Ngân hàng</CFormLabel>
                        <CFormInput onClick={() => setVisible(true)} onChange={(e) => onChange(3, e)} type="text" id="bankName" placeholder="Tên ngân hàng" value={bankName} readOnly={true} />
                    </div>

                    <div className="form-group-custom">
                        <CFormLabel htmlFor="cardNumber" className="col-sm-2 col-form-label text-nowrap">Số tài khoản</CFormLabel>
                        <CFormInput onChange={(e) => onChange(2, e)} type="text" id="cardNumber" placeholder="Số tài khoản" value={cardNumber} />
                    </div>

                    <div className="form-group-custom">
                        <CFormLabel htmlFor="cardName" className="col-sm-2 col-form-label text-nowrap">Tên chủ tài khoản</CFormLabel>
                        <CFormInput onChange={(e) => onChange(1, e)} type="text" id="cardName" placeholder="Tên chủ tài khoản" value={cardName} />
                    </div>

                    <div className="text-end px-3 mt-3" style={{ fontSize: 14, fontWeight: 400 }}> Quý khách vui lòng điền đúng thông tin ngân hàng. </div>

                    <div className="px-3 mt-4">
                        <CButton className="w-100" onClick={onSubmit}>
                            {(cardName && cardNumber && bankName) ? 'Cập nhật' : 'Thêm'}
                        </CButton>
                    </div>
                </div>

                <SModal visible={visible} className="custom-select-bank">
                    <CModalHeader style={{ background: "#ea03b8", color: "#fff" }}>
                        <div>Chọn ngân hàng</div>
                    </CModalHeader>
                    <CModalBody>
                        {
                            bank.map((item: any, index: number) => (
                                <div className="d-flex align-items-center mb-3" key={index} onClick={() => Selected(item.name)}>
                                    <div style={{ width: 120, flexShrink: 0 }} className="text-center">
                                        <img width={80} src={item.avatar} />
                                    </div>
                                    <div style={{ fontSize: 14, color: "#ea03b8", fontWeight: 500}}>{item.name}</div>
                                </div>
                            ))
                        }
                    </CModalBody>
                </SModal>

            </div>
        </Layout>
    )
}

export default AddBank;