import ConfirmDeleteModal from '@/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import Edit02Icon from '@/components/shared/icons/Edit02Icon';
import { ToastSuccess } from '@/components/shared/toast/Toast';
import {
  CButton,
  CFormInput,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSmartTable,
} from '@coreui/react-pro';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { ResponseStatus, mapResponseStatus } from '../../../shared/enumeration/ResponseStatus';
import { createIndexes, formatVND } from '../../../shared/utils/ultils';
import { AppDispatch } from '../../../store';
import CustomTableFooter from '../../shared/CustomTableFooter/CustomTableFooter';
import FilterIcon from '../../shared/icons/FilterIcon';
import PlusIcon from '../../shared/icons/PlusIcon';
import SearchIcon from '../../shared/icons/SearchIcon';
import TrashIcon from '../../shared/icons/TrashIcon';
import { getEntities, removeEntity, updateEntity } from './ketQuaQuayManagement.api';
import { fetching, ketQuaQuaySelectors, resetEntity, setFilterState } from './ketQuaQuayManagement.reducer';
import { IKetQuaQuay } from '@/shared/model/ketquaquay.model';
import KetQuaQuayUpdate from './KetQuaQuayUpdate';
import dayjs from 'dayjs';

const columns = [
  {
    key: 'id',
    label: 'ID',
    sorter: false,
  },
  {
    key: 'userId',
    label: 'Người chơi',
    sorter: false,
  },
  {
    key: 'phanQuaId',
    label: 'Phần Thưởng',
    sorter: false,
  },
  {
    key: 'value',
    label: 'Giá trị',
    sorter: false,
  },
  {
    key: 'note',
    label: 'Ghi chú',
    sorter: false,
  },
  {
    key: 'createdDate',
    label: 'Thời gian quay',
    sorter: false,
  },
  // {
  //   key: 'status',
  //   label: 'Tình trạng',
  //   sorter: false,
  //   _props: { width: '20%' },
  // },
  {
    key: 'actions',
    label: 'Thao tác',
    sorter: false,
    _props: { className: 'text-center' },
  },
];

interface IIndexUser extends IKetQuaQuay {
  index: number;
}

const KetQuaQuayManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector((state: RootState) => state.ketQuaQuayReducer);
  const { filterState, totalItems, totalPages, loading, updateEntitySuccess, deleteEntitySuccess } = initialState;
  const [createUser, setCreateUser] = useState<boolean>(false);
  const [updateUser, setUpdateUser] = useState<IKetQuaQuay | null>(null);

  const handleCreateUser = () => {
    setCreateUser(true);
  };

  const handleUpdateUser = (user: IKetQuaQuay) => () => {
    setUpdateUser(user);
  };

  const handleCancelUpdateUser = (update?: boolean) => {
    setUpdateUser(null);
  };

  const handlePaginationChange = (page: number) => {
    if (page !== 0) {
      window.scrollTo(0, 0);
      dispatch(setFilterState({ ...filterState, page: page }));
    }
  };

  const ketQuaQuays = useSelector(ketQuaQuaySelectors.selectAll);
  const indexedKetQuaQuay = createIndexes(ketQuaQuays, filterState);

  const handleUpdateStatus = (user: IKetQuaQuay) => () => {
    const status = user.status === ResponseStatus.ACTIVE ? ResponseStatus.INACTIVE : ResponseStatus.ACTIVE;
    dispatch(fetching());
    dispatch(updateEntity({ ...user, status }));
  };

  useEffect(() => {
    if (updateEntitySuccess && !createUser && !updateUser) {
      ToastSuccess('Cập nhật trạng thái thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  useEffect(() => {
    if (!deleteEntitySuccess) {
      dispatch(fetching());
      dispatch(getEntities(filterState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), deleteEntitySuccess]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getEntities(filterState));
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  useEffect(() => {
    if (deleteEntitySuccess) {
      ToastSuccess('Xoá người dùng thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntitySuccess]);

  const [chosenDelete, setChosenDelete] = useState<IKetQuaQuay | null>(null);

  const handleDelete = () => {
    if (chosenDelete) {
      dispatch(removeEntity(chosenDelete.id));
      setChosenDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setChosenDelete(null);
  };

  const [keyword, setKeyword] = useState<string>('');

  const onSearch = () => {
    dispatch(setFilterState({ ...filterState, page: 1, keyword }));
  }

  return (
    <>
      <div className="tab-outlet-content">
        <div className="table-context">
          <div className='d-flex'>
            <CFormInput value={keyword} onChange={(e) => setKeyword(e.target.value)}  placeholder="ID hoặc Tên đăng nhập" />
            <CButton style={{ height: 42, whiteSpace: 'nowrap'}} className="ms-2 btn-custom btn-sm text-white" onClick={onSearch}>
              Tìm kiếm
            </CButton>
          </div>
          {/* <div>
            <CButton className="btn-custom primary-500 btn-sm" onClick={handleCreateUser}>
              <PlusIcon /> Tạo kết quả quay mới
            </CButton>
          </div> */}
          <div className="d-none">
            <CInputGroup className="search-group me-2">
              <CInputGroupText id="search-addon">
                <SearchIcon />
              </CInputGroupText>
              <CFormInput placeholder="Tìm kiếm theo tên" aria-label="Username" aria-describedby="basic-addon1" />
            </CInputGroup>
            <CButton className="btn-custom gray-700 btn-sm">
              <FilterIcon /> Lọc
            </CButton>
          </div>
        </div>

        <div className="table-content">
          <CSmartTable
            header
            columns={columns}
            items={indexedKetQuaQuay}
            clickableRows
            noItemsLabel="Không có bản ghi"
            itemsPerPage={filterState.limit}
            scopedColumns={{
              createdDate: ({ index, ...value }: IIndexUser) => (
                <td>{dayjs(value.createdDate).format('HH:mm:ss DD/MM/YYYY')}</td>
              ),
              userId: ({ index, ...value }: IIndexUser) => (
                <td>
                  <div>{value?.user?.id} - {value?.user?.username}</div>
                </td>
              ),
              value: ({ index, ...value }: IIndexUser) => (
                <td>{ formatVND(value?.value ?? 0)}</td>
              ),
              phanQuaId: ({ index, ...value }: IIndexUser) => (
                <td>
                  <div>{value?.phanQua?.name}</div>
                </td>
              ),
              status: ({ index, ...value }: IIndexUser) => (
                <td>
                  <div className="d-flex align-items-center flex-nowrap">
                    <CFormSwitch
                      checked={value.status === ResponseStatus.ACTIVE}
                      className="me-2"
                      onChange={handleUpdateStatus(value)}
                      disabled={loading}
                    />
                    {mapResponseStatus[value.status]}
                  </div>
                </td>
              ),
              actions: ({ index, ...value }: IIndexUser) => (
                <td>
                  <CInputGroup className="custom-input-group-action justify-content-center">
                    <CInputGroupText onClick={handleUpdateUser(value)}>
                      <Edit02Icon />
                    </CInputGroupText>
                    {value.status !== ResponseStatus.ACTIVE ? (
                      <CInputGroupText onClick={() => setChosenDelete(value)}>
                        <TrashIcon />
                      </CInputGroupText>
                    ) : (
                      ''
                    )}
                  </CInputGroup>
                </td>
              ),
            }}
            tableProps={{
              hover: true,
              align: 'middle',
              responsive: 'lg',
              className: 'custom-table',
            }}
            tableHeadProps={{
              className: 'table-head',
            }}
            tableBodyProps={{
              className: 'table-body',
            }}
          />
        </div>

        {createUser ? <KetQuaQuayUpdate visible={createUser} setVisible={setCreateUser} /> : ''}
        {updateUser ? (
          <KetQuaQuayUpdate visible={Boolean(updateUser)} userObj={updateUser} setVisible={handleCancelUpdateUser} />
        ) : (
          ''
        )}
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>
      </div>
      {chosenDelete ? (
        <ConfirmDeleteModal
          visible={Boolean(chosenDelete)}
          handleOnClose={handleCancelDelete}
          handleDelete={handleDelete}
          title="Xoá kết quả quay khỏi hệ thống"
          content={
            <>
              Xác nhận xoá kết quả quay <span className="high-light">{chosenDelete.name}</span> khỏi hệ thống?
            </>
          }
        />
      ) : (
        ''
      )}
    </>
  );
};

export default KetQuaQuayManagement;
