import ConfirmDeleteModal from '@/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import Edit02Icon from '@/components/shared/icons/Edit02Icon';
import { ToastSuccess } from '@/components/shared/toast/Toast';
import {
  CAvatar,
  CButton,
  CFormInput,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSmartTable,
} from '@coreui/react-pro';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import avatar from '../../../../assets/img/avatar.jpg';
import { RootState } from '../../../reducers';
import { ResponseStatus, mapResponseStatus } from '../../../shared/enumeration/ResponseStatus';
import { mapRoleToString } from '../../../shared/enumeration/role';
import { createIndexes } from '../../../shared/utils/ultils';
import { AppDispatch } from '../../../store';
import CustomTableFooter from '../../shared/CustomTableFooter/CustomTableFooter';
import FilterIcon from '../../shared/icons/FilterIcon';
import PlusIcon from '../../shared/icons/PlusIcon';
import SearchIcon from '../../shared/icons/SearchIcon';
import TrashIcon from '../../shared/icons/TrashIcon';
import UserUpdate from './PhanQuaUpdate';
import { getEntities, removeEntity, updateEntity } from './phanQuaManagement.api';
import { fetching, phanQuaSelectors, resetEntity, setFilterState } from './phanQuaManagement.reducer';
import { IPhanQua } from '@/shared/model/phanqua.model';

const columns = [
  {
    key: 'id',
    label: 'ID',
    sorter: false,
  },
  {
    key: 'name',
    label: 'Tên',
    sorter: false,
  },
  // {
  //   key: 'value',
  //   label: 'Giá trị',
  //   sorter: false,
  // },
  // {
  //   key: 'note',
  //   label: 'Ghi chú',
  //   sorter: false,
  // },
  {
    key: 'status',
    label: 'Trạng thái',
    sorter: false,
  },
  {
    key: 'actions',
    label: 'Thao tác',
    sorter: false,
    _props: { className: 'text-center' },
  },
];

interface IIndexUser extends IPhanQua {
  index: number;
}

const UserManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector((state: RootState) => state.phanQuaReducer);
  const { filterState, totalItems, totalPages, loading, updateEntitySuccess, deleteEntitySuccess } = initialState;
  const [createUser, setCreateUser] = useState<boolean>(false);
  const [updateUser, setUpdateUser] = useState<IPhanQua | null>(null);

  const handleCreateUser = () => {
    setCreateUser(true);
  };

  const handleUpdateUser = (user: IPhanQua) => () => {
    setUpdateUser(user);
  };

  const handleCancelUpdateUser = (update?: boolean) => {
    setUpdateUser(null);
  };

  const handlePaginationChange = (page: number) => {
    if (page !== 0) {
      window.scrollTo(0, 0);
      dispatch(setFilterState({ ...filterState, page: page }));
    }
  };

  const phanquas = useSelector(phanQuaSelectors.selectAll);
  const indexedPhanQua = createIndexes(phanquas, filterState);

  const handleUpdateStatus = (user: IPhanQua) => () => {
    const status = user.status === ResponseStatus.ACTIVE ? ResponseStatus.INACTIVE : ResponseStatus.ACTIVE;
    dispatch(fetching());
    dispatch(updateEntity({ ...user, status }));
  };

  useEffect(() => {
    if (updateEntitySuccess && !createUser && !updateUser) {
      ToastSuccess('Cập nhật trạng thái thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  useEffect(() => {
    if (!deleteEntitySuccess) {
      dispatch(fetching());
      dispatch(getEntities(filterState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), deleteEntitySuccess]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getEntities(filterState));
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  useEffect(() => {
    if (deleteEntitySuccess) {
      ToastSuccess('Xoá người dùng thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntitySuccess]);

  const [chosenDelete, setChosenDelete] = useState<IPhanQua | null>(null);

  const handleDelete = () => {
    if (chosenDelete) {
      dispatch(removeEntity(chosenDelete.id));
      setChosenDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setChosenDelete(null);
  };

  const [keyword, setKeyword] = useState<string>('');

  const onSearch = () => {
    dispatch(setFilterState({ ...filterState, page: 1, keyword }));
  }

  return (
    <>
      <div className="tab-outlet-content">
        <div className="table-context">
          <div className='d-flex'>
            {/* <CFormInput value={keyword} onChange={(e) => setKeyword(e.target.value)}  placeholder="ID hoặc Tên đăng nhập" />
            <CButton style={{ height: 42, whiteSpace: 'nowrap'}} className="ms-2 btn-custom btn-sm text-white" onClick={onSearch}>
              Tìm kiếm
            </CButton> */}
          </div>
          <div>
            <CButton className="btn-custom primary-500 btn-sm" onClick={handleCreateUser}>
              <PlusIcon /> Thêm phần thưởng mới
            </CButton>
          </div>
          <div className="d-none">
            <CInputGroup className="search-group me-2">
              <CInputGroupText id="search-addon">
                <SearchIcon />
              </CInputGroupText>
              <CFormInput placeholder="Tìm kiếm theo tên" aria-label="Username" aria-describedby="basic-addon1" />
            </CInputGroup>
            <CButton className="btn-custom gray-700 btn-sm">
              <FilterIcon /> Lọc
            </CButton>
          </div>
        </div>

        <div className="table-content">
          <CSmartTable
            header
            columns={columns}
            items={indexedPhanQua}
            clickableRows
            noItemsLabel="Không có bản ghi"
            itemsPerPage={filterState.limit}
            scopedColumns={{
              status: ({ index, ...value }: IIndexUser) => (
                <td>
                  <div className="d-flex align-items-center flex-nowrap">
                    <CFormSwitch
                      checked={value.status === ResponseStatus.ACTIVE}
                      className="me-2"
                      onChange={handleUpdateStatus(value)}
                      disabled={loading}
                    />
                    {mapResponseStatus[value.status]}
                  </div>
                </td>
              ),
              actions: ({ index, ...value }: IIndexUser) => (
                <td>
                  <CInputGroup className="custom-input-group-action justify-content-center">
                    <CInputGroupText onClick={handleUpdateUser(value)}>
                      <Edit02Icon />
                    </CInputGroupText>
                    {value.status !== ResponseStatus.ACTIVE ? (
                      <CInputGroupText onClick={() => setChosenDelete(value)}>
                        <TrashIcon />
                      </CInputGroupText>
                    ) : (
                      ''
                    )}
                  </CInputGroup>
                </td>
              ),
            }}
            tableProps={{
              hover: true,
              align: 'middle',
              responsive: 'lg',
              className: 'custom-table',
            }}
            tableHeadProps={{
              className: 'table-head',
            }}
            tableBodyProps={{
              className: 'table-body',
            }}
          />
        </div>

        {createUser ? <UserUpdate visible={createUser} setVisible={setCreateUser} /> : ''}
        {updateUser ? (
          <UserUpdate visible={Boolean(updateUser)} userObj={updateUser} setVisible={handleCancelUpdateUser} />
        ) : (
          ''
        )}
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>
      </div>
      {chosenDelete ? (
        <ConfirmDeleteModal
          visible={Boolean(chosenDelete)}
          handleOnClose={handleCancelDelete}
          handleDelete={handleDelete}
          title="Xoá phần thưởng khỏi hệ thống"
          content={
            <>
              Xác nhận xoá phần quà <span className="high-light">{chosenDelete.name}</span> khỏi hệ thống?
            </>
          }
        />
      ) : (
        ''
      )}
    </>
  );
};

export default UserManagement;
