import WheelComponent from "./WheelComponent";
import axios from '../../../shared/config/axios-interceptor'
import { useEffect, useRef, useState } from "react";
import SModal from "@/components/shared/Modal/SModal";
import { CButton, CModalBody, CModalFooter, CModalHeader } from "@coreui/react-pro";
import FallingShapes from "./FallingShapes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { ToastError } from "@/components/shared/toast/Toast";
import { AppDispatch } from "@/store";

export default function Spin() {
  const dispatch = useDispatch<AppDispatch>()
  const [phanThuong, setPhanThuong ] = useState([]);
  const [showPhanThuong, setShowPhanThuong] = useState<any>(null);
  const { user } = useSelector((state: RootState) => state.authentication)
  const [loading, setLoading] = useState(false);
  const [ketQua, setKetQua] = useState<any>(null);
  const [luotQuay, setLuotQuay] = useState(0);
  const ketqua1 = useRef<any>(null);
  const getPhanThuong = async () => {
    try {
      const res = await axios.get('/phanqua?status=ACTIVE');
      setPhanThuong(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }
  
  useEffect(() => {
    getPhanThuong();
    getLuotQuay();
  }, []);


  useEffect(() => {
    const i = setInterval(() => {
      getLuotQuay();
    }, 1000);

    return () => clearInterval(i);
  }, []);


  const getLuotQuay = async () => {
    try {
      const res = await axios.get('/luotquay/count');
      setLuotQuay(res?.data);
    } catch (error) {
      console.log(error);
    }
  }

  if(!phanThuong?.length) return <div></div>;

  const handleQuay = async () => {
    try {
      setLoading(true);
      const res = await axios.post('/ketquaquay', {});
      if(res){
        setKetQua(res?.data?.phanQua?.name);
        ketqua1.current = res?.data?.phanQua?.name
        await getLuotQuay();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const segments = phanThuong?.map( (item: any) => item?.name);
  const segColors = [
    "#f68585",
    '#f7b984',
    '#5dc1f2',
    '#ece7e6',
    '#c985f4',
    '#5ec4f8',
    '#f6b883',
    '#eeeeee',
    '#f7a5ef',
    '#f3edf1'
  ];

  const onFinished = async (winner: any) => {
    // const res = await axios.post('/ketquaquay', {});
    // setShowPhanThuong(res?.data?.phanQua?.name);
    setShowPhanThuong(ketqua1.current)
    setLoading(false);
  };

  return (
    <div className="App">
      <div className="mt-3 text-center soluot">Số lượt quay: {luotQuay}</div>
      <div id="wheelCircle" style={{ padding: "10px", position: "relative" }}>
        <div style={{ position: "relative" }}>
          <div className={'disabled-wheel ' + (loading ? ' active' : '')}></div>
          <div className={'disabled-wheel ' + (luotQuay ? '' : ' active')} onClick={() => ToastError('Bạn đã hết lượt quay.')}></div>
          <WheelComponent
            onClickSubmit={handleQuay}
            segments={segments}
            segColors={segColors}
            winningSegment={ketQua}
            onFinished={(winner: any) => onFinished(winner)}
            primaryColor="rgb(235, 66, 109)"
            primaryColoraround="#f40efe"
            contrastColor="white"
            buttonText="LOVE"
            isOnlyOnce={false}
            size={280}
            upDuration={1000}
            downDuration={1500}
          />
        </div>
      </div>

      <SModal visible={showPhanThuong ? true : false} onClose={() => setShowPhanThuong(null)} alignment="center">
        <FallingShapes />
        <CModalHeader style={{ position: "relative", zIndex: "999", opacity: 0.9 }}>Thông báo</CModalHeader>
        <CModalBody style={{ textAlign: "center", minHeight: "250px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div>
            <h4 className="mb-4">Chúc mừng bạn đã trúng thưởng</h4>
            <h1 style={{ color: "#ea03b8" }}>{showPhanThuong}</h1>
          </div>
        </CModalBody>

        <CModalFooter className="justify-content-center">
          <CButton onClick={() => setShowPhanThuong(null)} style={{ backgroundColor: "#ea03b8", color: "white", cursor: "pointer", position: "relative", zIndex: "999", opacity: 0.9 }}>Xác nhận</CButton>
        </CModalFooter>
      </SModal>
    </div>
  );
}
