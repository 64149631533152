import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react-pro';
import { Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ResponseStatus, mapResponseStatus, responseStatusArray } from '../../../shared/enumeration/ResponseStatus';
import SModal from '../../shared/Modal/SModal';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { AppDispatch } from '../../../store';
import { ToastSuccess } from '../../shared/toast/Toast';
import { fetching, resetEntity } from './luotQuayManagement.reducer';
import { ILuotQuay, INewLuotQuay } from '@/shared/model/luotquay.model';
import { createEntity, updateEntity } from './luotQuayManagement.api';
import { IParams, ISelectValue } from '@/shared/shared-interfaces';
import { getEntities as getPhanQua } from '../PhanQuaManagement/phanQuaManagement.api';
import { getEntities as getUsers } from '../UserManagement/usersManagement.api';
import Select, { SingleValue } from 'react-select';
import { phanQuaSelectors } from '../PhanQuaManagement/phanQuaManagement.reducer';
import { userSelectors } from '../UserManagement/usersManagement.reducer';

interface IUserUpdateProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  userObj?: ILuotQuay;
}

const LuotQuayUpdate = (props: IUserUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector((state: RootState) => state.luotQuayReducer);
  const { updateEntitySuccess } = initialState;
  const { visible, setVisible, userObj } = props;
  const handleOnClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (updateEntitySuccess) {
      setVisible(false);
      ToastSuccess(userObj ? 'Cập nhật lượt quay thành công' : 'Tạo lượt quay thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  const phanQuaItems = useSelector(phanQuaSelectors.selectAll);

  useEffect(() => {
    dispatch(getPhanQua({ limit: 30, page: 1, sortBy: 'id', sortOrder: 'DESC', status: 'ACTIVE' } as IParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const userItems = useSelector(userSelectors.selectAll);

  useEffect(() => {
    dispatch(getUsers({ limit: 50, page: 1, sortBy: 'id', sortOrder: 'DESC', status: 'ACTIVE' } as IParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const initialValues: INewLuotQuay = {
    userId: 0,
    phanQuaId: 0,
    money: 0,
    note: '',
    status: ResponseStatus.INACTIVE,
  };

  const validationSchema = Yup.object().shape({
    userId: Yup.number().required('Không được để trống').nullable(),
    phanQuaId: Yup.number().required('Không được để trống').nullable(),
    note: Yup.string().trim(),
    money: Yup.number().required('Không được để trống').nullable(),
  });


  const returnOption = (optionValue: number | null | undefined, array: ISelectValue<string>[]) => {
    return array.find(({ value }) => Number(value) === optionValue);
  };


  const optionItems: any = () => {
    return phanQuaItems.map( (item) => {
      return {
        value: item.id,
        label: item.name,
      };
    })
  }


  const returnOptionUser = (optionValue: number | null | undefined, array: ISelectValue<string>[]) => {
    return array.find(({ value }) => Number(value) === optionValue);
  };


  const optionItemsUser: any = () => {
    return userItems.map( (item) => {
      return {
        value: item.id,
        label: item.username,
      };
    })
  }
  
  return (
    <SModal visible={visible} onClose={handleOnClose} backdrop="static" className="custom-modal">
      <Formik
        initialValues={userObj || initialValues}
        validationSchema={validationSchema}
        // validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(value) => {
          dispatch(fetching());
          if (userObj) {
            dispatch(updateEntity(value as ILuotQuay));
          } else {
            dispatch(createEntity(value));
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue }) => (
          <CForm onSubmit={handleSubmit} className="custom-form">
            <>
              <CModalHeader>{userObj ? 'Chỉnh sửa lượt quay' : 'Tạo lượt quay'}</CModalHeader>
              <CModalBody>
                <CRow className="gap-20">


                 <CCol xs="12" onClick={(e) => e.stopPropagation()}>
                    <CFormLabel className="mb-6 label-gray-700">Phần thưởng</CFormLabel>
                    <Select
                      // menuIsOpen={true}
                      defaultValue={null}
                      className="custom-select form-height-44"
                      classNamePrefix="react-select"
                      value={returnOption(Number(values.phanQuaId), optionItems()) as any}
                      onChange={(newValue: SingleValue<ISelectValue<string>>) => {
                        console.log(newValue?.value, 'newValue?.value');
                        setFieldValue(`phanQuaId`, Number(newValue?.value));
                      }}                      
                      noOptionsMessage={() => <>Không có phần thưởng nào</>}
                      id={'phanQuaId'}
                      options={optionItems()}
                      placeholder={'Chọn phần thưởng'}
                      isClearable={true}
                      closeMenuOnSelect={true}
                      menuPosition={'absolute'}
                    />
                  </CCol>

                  <CCol xs="12" onClick={(e) => e.stopPropagation()}>
                    <CFormLabel className="mb-6 label-gray-700">Thành viên</CFormLabel>
                    <Select
                      // menuIsOpen={true}
                      defaultValue={null}
                      className="custom-select form-height-44"
                      classNamePrefix="react-select"
                      value={returnOptionUser(Number(values.userId), optionItemsUser()) as any}
                      onChange={(newValue: SingleValue<ISelectValue<string>>) => {
                        setFieldValue(`userId`, Number(newValue?.value));
                      }}                      
                      noOptionsMessage={() => <>Không có người dùng nào</>}
                      id={'userId'}
                      options={optionItemsUser()}
                      placeholder={'Chọn người dùng'}
                      isClearable={true}
                      closeMenuOnSelect={true}
                      menuPosition={'absolute'}
                    />
                  </CCol>


                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Số tiền</CFormLabel>

                    <CFormInput
                      className="form-height-44"
                      name="money"
                      autoComplete="off"
                      value={values.money}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.money && touched.money}
                    />
                    <CFormFeedback invalid className={!!errors.money && touched.money ? 'd-block' : 'd-none'}>
                      {errors.money}
                    </CFormFeedback>
                  </CCol>


                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Ghi chú</CFormLabel>

                    <CFormInput
                      className="form-height-44"
                      name="note"
                      autoComplete="off"
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.note && touched.note}
                    />
                    <CFormFeedback invalid className={!!errors.note && touched.note ? 'd-block' : 'd-none'}>
                      {errors.note}
                    </CFormFeedback>
                  </CCol>

                  {/* <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Trạng thái</CFormLabel>
                    <CFormSelect
                      className="form-height-44"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.status && touched.status}
                    >
                      {responseStatusArray.map((item, index) => (
                        <option key={index} value={item}>
                          {mapResponseStatus[item]}
                        </option>
                      ))}
                    </CFormSelect>
                    <CFormFeedback invalid className={!!errors.status && touched.status ? 'd-block' : 'd-none'}>
                      {errors.status}
                    </CFormFeedback>
                  </CCol> */}
                </CRow>
              </CModalBody>
              <CModalFooter className="d-flex justify-content-end">
                <CButton
                  className="btn-custom minw-120 variant-gray-300"
                  type="button"
                  onClick={() => {
                    resetForm();
                    setVisible(false);
                  }}
                >
                  Huỷ
                </CButton>
                <CButton className="btn-custom minw-120 primary-500" type="submit">
                  Lưu
                </CButton>
              </CModalFooter>
            </>
          </CForm>
        )}
      </Formik>
    </SModal>
  );
};

export default LuotQuayUpdate;
