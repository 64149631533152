import { CButton, CModalBody, CModalFooter } from '@coreui/react-pro';
import SModal from '../Modal/SModal';

interface IConfirmDeleteModalProps {
  visible: boolean;
  handleOnClose: () => void;
  title: string;
  content: React.ReactNode;
  handleDelete: () => void;
}

const ConfirmDeleteModal = (props: IConfirmDeleteModalProps) => {
  const { visible, handleOnClose, title, content, handleDelete } = props;

  return (
    <>
      <SModal
        visible={visible}
        onClose={handleOnClose}
        backdrop="static"
        className="custom-modal confirm-delete-modal"
        alignment="center"
      >
        <CModalBody>
          <div className="confirm-delete-content">
            <p className="title">{title}</p>
            <p className="content mb-0">{content}</p>
          </div>
        </CModalBody>
        <CModalFooter className="d-flex justify-content-center" style={{ gap: '16px' }}>
          <CButton className="btn-custom min-width-160 variant-gray-300" onClick={handleOnClose}>
            Huỷ
          </CButton>
          <CButton className="btn-custom min-width-160 error-500" onClick={handleDelete}>
            Xác nhận
          </CButton>
        </CModalFooter>
      </SModal>
    </>
  );
};

export default ConfirmDeleteModal;
