import {
  CButton,
  CFormInput,
  CModalBody,
  CModalFooter,
} from '@coreui/react-pro'
import Layout from './Layout'
import SModal from '@/components/shared/Modal/SModal'
import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import { IGame } from '@/shared/model/game.model'
import {
  ToastErrorBottom,
  ToastSuccessBottom,
} from '@/components/shared/toast/Toast'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import KyQuay from './KyQuay'
import InfoIcon from '@/components/shared/icons/web/InfoIcon'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import Spin from './Spin'

const Home = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate, location } = useRouter()

  const [modal, setModal] = useState(false)

  const [tienDat, setTienDat] = useState<number>(0)
  const [biDat, setBiDat] = useState<number>(0)
  const [ky, setKy] = useState<IGame | null>(null)
  const [disableDatCuoc, setDisableDatCuoc] = useState(false)
  const [load, setLoad] = useState(false)
  const { user, deposit } = useSelector((state: RootState) => state.authentication)
  const [disable, setDisable] = useState(false)

  const subTienDatHandle = () => {
    if (tienDat > 10000) {
      setTienDat(tienDat - 10000)
    }
  }

  const getKy = async () => {
    try {
      const response = await axios.get('/draws/get-current')
      setKy(response.data)
      console.log('Success:', response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getKy()
    dispatch(getProfile())
  }, [load])

  const addTienDatHandle = () => {
    setTienDat(tienDat + 10000)
  }

  const money = [20, 30, 50, 100, 500, 1000, 50000, 100000, 200000]

  const onChangeTienDatHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (Number.isNaN(Number(value))) return
    setTienDat(Number(value))
  }

  const closeModal = () => {
    setModal(false)
    setBiDat(0)
  }

  const openModal = (biDat: number) => {
    setModal(true)
    setBiDat(biDat)
  }

  const submitHanle = async () => {
    setDisable((true))
    if (disableDatCuoc) {
      ToastErrorBottom('Đã hết thời gian nhập')
      setModal(false)
      setDisable((false))
      return
    }

    if (user?.money && user?.money < tienDat || user?.money == 0) {
      ToastErrorBottom('Số dư không đủ')
      setDisable((false))
      return
    }

    if (tienDat <= 10) {
      ToastErrorBottom('Vui lòng nhập số tiền cược lớn hơn 10')
      setModal(false)
      setDisable((false))
      return
    }

    try {
      const response = await axios.post('/history', {
        KyId: ky?.id,
        voted: biDat,
        money: tienDat,
      })
      if (response) {
        setModal(false)
        setBiDat(0)
        ToastSuccessBottom('Nhập thành công')
        dispatch(getProfile())
        setDisable((false))
        setTienDat(0)
      }
    } catch (error) {
      setModal(false)
      setBiDat(0)
      ToastErrorBottom('Nhập thất bại')
      dispatch(getProfile())
      setDisable((false))
      console.error('Error:', error)
    }
  }

  const [showModalTT, setShowModalTT] = useState(false)

  return (
    <Layout>
      <KyQuay
        load={load}
        ky={ky}
        setDisableDatCuoc={setDisableDatCuoc}
        setLoad={setLoad}
      />

      <div className="item-history d-flex justify-content-center align-items-center">
        <div className='d-flex align-items-center'>
          <div style={{ fontSize: 14, color: "rgb(55 65 81)", fontWeight: "500" }}>Hôm nay</div>
          <div className='py-1 px-2 rounded-1 ms-2' style={{ fontSize: 12, background: "#ea03b8" }}>{(ky?.id ?? 0) - 1}</div></div>
      </div>

      <div className="box-result d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h2 className='m-0' style={{ color: "rgb(55, 65, 81)", fontSize: 24 }}>Vòng Quay Tình Yêu</h2>
        </div>
      </div>


      <div className="box-datcuoc px-3">
        <div>
          <div className="d-flex gap-1">
            <button
              style={{
                background: '#ea03b8',
                height: 108,
              }}
              className={biDat === 1 ? 'active justify-content-center ' : 'justify-content-center '}
              onClick={() => openModal(1)}
            >
              <span style={{
                marginTop: 5, fontSize: 16,
                fontWeight: '500',
                color: '#fff'

              }}>TÌNH YÊU</span>
            </button>
            <button
              style={{
                background: '#ea03b8',
                height: 108,
              }}
              className={biDat === 2 ? 'active justify-content-center ' : 'justify-content-center '}
              onClick={() => openModal(2)}
            >
              <span style={{ marginTop: 5, fontSize: 16, color: '#fff' }}>HÔN NHÂN</span>
            </button>
          </div>
        </div>
      </div>

      <div className='text-end mt-4 px-3'>
        <span style={{ cursor: 'pointer' }} onClick={() => setShowModalTT(true)}>
          <InfoIcon
            color="#fff"
            fill="#113b49"
            style={{ borderRadius: '50%', background: '#fff' }}
          />
        </span>
      </div>

      <div>
        <Spin />
      </div>

      <SModal alignment="center"
        visible={showModalTT}
        backdrop="static"
        onClose={() => setShowModalTT(false)}
        className="custom-modal">
        <CModalBody>
          <p style={{ fontSize: 15, fontWeight: 500 }}>Hệ thống nghiêm cấm mọi hình thức nhập gian lận, nếu bị phát hiện, web có quyền hạn thu hồi tất cả số tiền trên Web. Nếu có bất kì ý kiến hoặc kiến nghị nào, Vui lòng Lựa chọn "CSKH" ở dưới menu và để lại lời nhắn.</p>

          <p style={{ fontSize: 15, fontWeight: 500 }}>Để đảm bảo web được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách nhận được phần quà ngẫu nhiên may mắn từ Web, khi rút tiền cần thực hiện đóng phí duy trì theo hạn mức rút tiền như sau:</p>

          <p style={{ fontSize: 15, fontWeight: 500 }}>- Hạn mức rút tài khoản dưới 200 triệu tương ứng 15% phí</p>

          <p style={{ fontSize: 15, fontWeight: 500 }}> - Hạn mức rút tài khoản dưới 500 triệu tương ứng với 20% phí</p>

          <p style={{ fontSize: 15, fontWeight: 500 }}> - Hạn mức rút tài khoản trên 500 triệu tương ứng với 30% phí</p>
        </CModalBody>
        <CModalFooter className='d-flex justify-content-center'>
          <div
            className="w-50 m-0 text-center rounded-3 fw-semibold"
            onClick={() => setShowModalTT(false)}
            style={{
              color: '#fff',
              background: "#ea03b8",
              height: 40,
              alignContent: 'center',
              cursor: 'pointer',
            }}
          >
            Xác nhận
          </div>
        </CModalFooter>
      </SModal>

      <SModal
        alignment="center"
        visible={modal}
        backdrop="static"
        className="custom-modal custom-modal-datcuoc"
      >
        <CModalBody>
          <div>
            <div className="mb-3 py-2 px-3 rounded-2" style={{ fontSize: 14, color: '#fff', background: "#ea03b8" }}>DỮ LIỆU QUAY</div>
            <div className="form-input d-flex justify-content-center align-items-center mb-2">
              <div className="sub" onClick={subTienDatHandle}>
                -
              </div>
              <CFormInput
                onChange={(e) => onChangeTienDatHandle(e)}
                className="text-center fw-normal"
                type="text"
                value={tienDat}
              />
              <div className="add" onClick={addTienDatHandle}>
                +
              </div>
            </div>
            <div className='text-center fw-normal' style={{ color: "rgb(239 68 68)" }}>{(tienDat && (user?.money && user?.money < tienDat || user?.money == 0)) ? 'Số dư không đủ' : ''}</div>
          </div>
        </CModalBody>
        <CModalFooter >
          <div className="d-flex gap-3 align-items-center w-100">
            <div
              className="w-50 m-0 text-center rounded-3 fw-semibold"
              onClick={closeModal}
              style={{
                fontWeight: '500',
                color: '#000',
                background: "#d1d5db",
                height: 40,
                alignContent: 'center',
                cursor: 'pointer',
              }}
            >
              Hủy
            </div>
            <CButton
              className="w-50 m-0 text-center rounded-3 fw-semibold"
              onClick={submitHanle}
              style={{
                color: '#fff',
                background: "#ea03b8",
                height: 40,
                alignContent: 'center',
                cursor: 'pointer',
              }}
              disabled={disable}
            >
              Xác nhận
            </CButton>
          </div>

        </CModalFooter>
      </SModal>
    </Layout>
  )
}

export default Home
