import { useEffect, useRef, useState } from "react";

const WheelComponent = ({
  segments,
  segColors,
  winningSegment,
  onFinished,
  onRotate,
  onRotatefinish,
  primaryColor,
  primaryColoraround,
  contrastColor,
  buttonText,
  isOnlyOnce = true,
  size = 290,
  upDuration = 1000,
  downDuration = 100,
  fontFamily = "times new roman",
  width = 100,
  height = 100,
  onClickSubmit
}: any) => {
  let currentSegment = "";
  let isStarted = false;
  const [isFinished, setFinished] = useState(false);
  let timerHandle: any = 0;
  const timerDelay = segments.length;
  let angleCurrent = 0;
  let angleDelta = 0;
  let canvasContext: any = null;
  let leg = segments.length ?? 0;
  let maxSpeed = Math.PI / leg;
  const upTime = segments.length * upDuration;
  const downTime = segments.length * downDuration;
  let spinStart = 0;
  let frames = 0;
  const centerX = 300;
  const centerY = 300;
  
  const [isSpinning, setIsSpinning] = useState(false); // Track whether it's spinning

  // useEffect(() => {
  //   wheelInit();
  //   setTimeout(() => {
  //     window.scrollTo(0, 1);
  //   }, 0);
  // }, []);

  const data = useRef<any>(null);


  useEffect(() => {
    wheelInit();
    data.current = winningSegment;  // Gán giá trị cho data.current
  }, [winningSegment]);

  const wheelInit = () => {
    initCanvas();
    wheelDraw();
  };


  const initCanvas = () => {
    let canvas: any = document.getElementById("canvas");
    if (navigator.appVersion.indexOf("MSIE") !== -1) {
      canvas = document.createElement("canvas");
      canvas.setAttribute("width", width);
      canvas.setAttribute("height", height);
      canvas.setAttribute("id", "canvas");
      document?.getElementById("wheel")?.appendChild(canvas);
    }
    canvas.addEventListener("click", spin, false);
    canvasContext = canvas.getContext("2d");
  };

  const spin = () => {
    if (!isSpinning) {
      setIsSpinning(true);
      if (timerHandle === 0) {
        spinStart = new Date().getTime();
        maxSpeed = Math.PI / segments.length;
        frames = 0;
        timerHandle = setInterval(onTimerTick, timerDelay);
      }
    }
  };

  const onTimerTick = () => {
    frames++;
    draw();
    const duration = new Date().getTime() - spinStart;
    let progress = 0;
    let finished = false;

    const totalTargetRounds = 15; // Tổng số vòng quay mong muốn (10-20 vòng)
    const targetAngle = totalTargetRounds * Math.PI * 2; // Số góc cần quay

    if (duration < upTime) {
      progress = duration / upTime;
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
    } else {
      if (data.current) {
        if (currentSegment === data.current && frames > segments.length) {
          progress = duration / upTime;
          // Tính angleDelta sao cho vòng quay sẽ quay nhiều vòng hơn trước khi dừng
          const totalProgress = Math.min(progress, 1);
          angleDelta = maxSpeed * Math.sin((totalProgress * Math.PI) / 2 + Math.PI / 2);

          // Điều chỉnh angleDelta để đạt được số vòng quay mục tiêu
          const totalRotation = angleCurrent + angleDelta;
          if (totalRotation < targetAngle) {
            angleDelta *= (targetAngle - totalRotation) / (targetAngle - angleCurrent);
          }

          progress = 1;
        } else {
          progress = duration / downTime;
          angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
        }
      } else {
        progress = duration / downTime;
        if (progress >= 0.8) {
          angleDelta = (maxSpeed / 1.2) * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
        } else if (progress >= 0.98) {
          angleDelta = (maxSpeed / 2) * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
        } else {
          angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
        }
      }
      if (progress >= 1) finished = true;
    }

    angleCurrent += angleDelta;
    while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2;

    if (finished) {
      setFinished(true);
      onFinished(currentSegment);
      clearInterval(timerHandle);
      timerHandle = 0;
      angleDelta = 0;
      setIsSpinning(false); // Reset the spinning state
    }
  };


  const wheelDraw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const draw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const drawSegment = (key: any, lastAngle: any, angle: any) => {
    const ctx = canvasContext;
    const value = segments[key];
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, lastAngle, angle, false);
    ctx.lineTo(centerX, centerY);
    ctx.closePath();
    ctx.fillStyle = segColors[key];
    ctx.fill();
    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate((lastAngle + angle) / 2);
    if(segColors[key] == '#f68585') { // 1
      ctx.fillStyle = "#ea1bd7";
    }else if(segColors[key] == '#f7b984') { // 2
      ctx.fillStyle = "#5f2efe";
    }else if(segColors[key] == '#5dc1f2') { // 3
      ctx.fillStyle = "#db1b0e";
    }else if(segColors[key] == '#ece7e6') { // 4
      ctx.fillStyle = "#1cca20";
    }else if(segColors[key] == '#c985f4') { // 5
      ctx.fillStyle = "#fd003b";
    }else if(segColors[key] == '#5ec4f8') { // 6
      ctx.fillStyle = "#ff04fa";
    }else if(segColors[key] == '#f6b883') { // 7
      ctx.fillStyle = "#5c35f4";
    }else if(segColors[key] == '#eeeeee') { // 8
      ctx.fillStyle = "#23e956";
    }else if(segColors[key] == '#f7a5ef') { // 9
      ctx.fillStyle = "#ff1619";
    }else if(segColors[key] == '#f3edf1') { //10
      ctx.fillStyle = "#00c613";
    }
    ctx.font = "bolder 1.4em " + fontFamily;
    ctx.fillText(value.substr(0, 21), size / 2 + 20, 0);
    ctx.restore();
  };

  const drawNeedle = () => {
    const ctx = canvasContext;
    ctx.lineWidth = 1;
    ctx.strokeStyle = contrastColor || "white";
    ctx.fillStyle = '#3fe3f1';
    ctx.beginPath();
    ctx.moveTo(centerX + 15, centerY - 60);
    ctx.lineTo(centerX - 15, centerY - 60);
    ctx.lineTo(centerX, centerY - 75);
    ctx.closePath();
    ctx.fill();
  
    // Tính góc hiện tại của kim
    const angle = angleCurrent + Math.PI / 2;
    
    // Tính segment hiện tại (segment chiến thắng)
    let i = segments.length - Math.floor((angle / (Math.PI * 2)) * segments.length) - 1;
    if (i < 0) i = i + segments.length;
  
    // Hiển thị segment chiến thắng trên kim
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "transparent";
    ctx.font = "bold 1.5em " + fontFamily;
    currentSegment = segments[i];
  
    if (isStarted) {
      // Hiển thị tên của segment chiến thắng ngay bên dưới kim
      ctx.fillText(currentSegment, centerX + 10, centerY - size - 40);
    }
  };
  

  // const drawNeedle = () => {
  //   const ctx = canvasContext;
  //   ctx.lineWidth = 1;
  //   ctx.strokeStyle = contrastColor || "white";
  //   ctx.fillStyle = '#3fe3f1';
  //   ctx.beginPath();
  //   ctx.moveTo(centerX + 15, centerY - 60);
  //   ctx.lineTo(centerX - 15, centerY - 60);
  //   ctx.lineTo(centerX, centerY - 75);
  //   ctx.closePath();
  //   ctx.fill();
  //   const change = angleCurrent + Math.PI / 2;
  //   let i = segments.length - Math.floor((change / (Math.PI * 2)) * segments.length) - 1;
  //   if (i < 0) i = i + segments.length;
  //   ctx.textAlign = "center";
  //   ctx.textBaseline = "middle";
  //   ctx.fillStyle = "transparent";
  //   ctx.font = "bold 1.5em " + fontFamily;
  //   currentSegment = segments[i];
  //   if (isStarted) {
  //     ctx.fillText(currentSegment, centerX + 10, centerY - size - 40);
  //   }
  // };

  const drawWheel = () => {
    const ctx = canvasContext;
    let lastAngle = angleCurrent;
    const len = segments.length;
    const PI2 = Math.PI * 2;
    ctx.lineWidth = 1;
    ctx.strokeStyle = primaryColor || "black";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.font = "1em " + fontFamily;
  
    // Draw each segment of the wheel
    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent;
      drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }
  
    // Draw the center circle (spin button)
    ctx.beginPath();
    ctx.arc(centerX, centerY, 60, 0, PI2, false);
    ctx.closePath();
    ctx.fillStyle = primaryColor || "black";
    ctx.lineWidth = 5;
    ctx.strokeStyle = contrastColor || "white";
    ctx.fill();
    ctx.font = "bold 1.5em " + fontFamily;
    ctx.fillStyle = contrastColor || "white";
    ctx.textAlign = "center";
    ctx.fillText(buttonText || "Spin", centerX, centerY + 3);
    ctx.stroke();
  
    // Draw the outer circle (border)
    ctx.beginPath();
    ctx.arc(centerX, centerY, size, 0, PI2, false);
    ctx.closePath();
    ctx.lineWidth = 17;
    ctx.strokeStyle = primaryColoraround || "white";
    ctx.stroke();
  
    // Draw the outer dots around the wheel's edge
    drawDotsAroundWheel();  // Make sure this is called AFTER drawing the wheel and its border
  };
  
  let lastTime = 0; // Biến theo dõi thời gian để điều chỉnh nhấp nháy

  const drawDotsAroundWheel = () => {
    const ctx = canvasContext;
    const numDots = 20; // Number of dots around the wheel
    const dotRadius = 6; // Radius of each dot
    const radius = size; // Radius is the same as the size of the wheel (edge of the wheel)
  
    // Lấy thời gian hiện tại
    const currentTime = Date.now();
    
    // Điều chỉnh thời gian để tạo hiệu ứng nhấp nháy
    const timeElapsed = currentTime - lastTime;
  
    // Nếu đã trôi qua 200ms, làm mới lại thời gian
    if (timeElapsed > 50) {
      lastTime = currentTime;
    }
  
    // Tính toán giá trị của hiệu ứng nhấp nháy
    const blinkEffect = Math.sin(currentTime / 200); // Điều chỉnh giá trị này để thay đổi tốc độ nhấp nháy
  
    // Vẽ các dot quanh vòng quay
    for (let i = 0; i < numDots; i++) {
      const angle = (i / numDots) * Math.PI * 2; // Vị trí cố định của các dot
      const x = centerX + Math.cos(angle) * radius;  // Tọa độ X của dot
      const y = centerY + Math.sin(angle) * radius;  // Tọa độ Y của dot
  
      // Đổi màu theo hiệu ứng nhấp nháy, cứ 1 dot là đỏ và 1 dot là trắng
      const color = (i % 2 === 0) ? (blinkEffect > 0 ? "#3fe3f1" : "#ffffff") : (blinkEffect > 0 ? "#ffffff" : "#3fe3f1");
      
      // const color = blinkEffect > 0 ? "#3fe3f1" : "#ffffff";

      ctx.beginPath();
      ctx.arc(x, y, dotRadius, 0, Math.PI * 2, false); // Vẽ mỗi dot tại vị trí đã tính toán
      ctx.fillStyle = color; // Đặt màu của dot
      ctx.fill();
    }
  };
  
  
  


  const clear = () => {
    const ctx = canvasContext;
    ctx.clearRect(0, 0, 600, 600);
  };

  return (
    <div id="wheel" style={{ padding: 0 }} onClick={onClickSubmit}>
      <canvas
        id="canvas"
        width="600"
        height="600"
        style={{
          pointerEvents: isFinished && isOnlyOnce ? "none" : "auto"
        }}
      />
    </div>
  );
};

export default WheelComponent;
